import React from 'react';
import logo from './logo.png';
import Footer from './Footer';
import './App.css';
import './site.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p className="Content-glow">Rising Phoenix</p>
        <img src={logo} className="App-logo" alt="logo" />
        <p className="Content-glow">Software</p>
      </header>
      <Footer />
    </div>
  );
}

export default App;
