import React, { useEffect, useState } from 'react';
import './Footer.css';

function Footer() {
    const [version, setVersion] = useState('unknown');

    const fetchData = () => {
        return fetch("/version")
            .then((response) => {if (response.ok) { return response.text()} else {return 'unknown';}})
            .then((data) => setVersion(data));
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
      <div className="Footer">
        <span className="Muted">NOTE:</span> This page is being created in small incremental improvements over time.  This is an initial version ({version}); far from the final (or complete) version.
      </div>
    );
}

export default Footer;
